import React, { useState } from "react";
import Nav from "../components/Intake/Nav";
import HeroV3 from "../components/Intake/HeroV3";
import AffiliateWhyHubble from "../components/Intake/AffiliateWhyHubble"
import AffiliatePress from "../components/Intake/AffiliatePress"
import OrderSectionAvg from "../components/Intake/OrderSectionAvg";
import "../styles/pages/intake-hydro-skyhy.scss"
import ContactsCharacteristics from "../components/Intake/ContactsCharacteristicsV3";
import AffiliateCallToAction from '../components/Intake/AffiliateCallToAction';
import ContactsCartProducts from "../components/Intake/ContactsCartProducts";
import HomeCtaAvg from "../components/HomeCtaAvg";

const IntakePage = () => {
  return (
    <div className="avg hubble-content-hydro-skyhy v3">
      <Nav isV3 />
      <HeroV3 isHydroSkyhy isAvg/>
      <AffiliateCallToAction isAvg isIntake/>
      <ContactsCharacteristics isV3 isAvg/>
      <AffiliateWhyHubble isV3/>
      <HomeCtaAvg />
      <div className="affiliate-press-container grid-section">
        <AffiliatePress />
      </div>
      <ContactsCartProducts/>
      <OrderSectionAvg isHydroSkyhy isIronclad={true} isV3 />
    </div>
  );
}

export default IntakePage
